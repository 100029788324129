<template>
    <div class="cd-body">
        <list :menu="menu" :total="total" :limit="limit" :isLoading="isLoading">
            <template v-slot:header-left>
                <span>肺結核(TB)判讀</span>
            </template>
            <template v-slot:header-right>
                <!-- 篩選 -->
                <div v-if="windowWidth <= 1024">
                    <vs-button class="cd-button-2w-icon rounded" type="flat" icon-pack="feather" icon="icon-filter" @click="switchFilter">{{ $t('apptMgmt.filter') }}</vs-button>
                </div>
            </template>
            <template v-slot:sidebar>
                <div class="mb-base">
                    <vs-input icon-no-border clearable icon="icon-search" icon-pack="feather" class="w-full input-rounded-full" v-model="query" />
                </div>
                <div class="mb-base" v-if="unitList.length > 0">
                    <vs-radio class="w-full mb-3" v-model="unit" :vs-name="'unit'" :vs-value="null" @change="getData">{{ $t('membersMgmt.unit') }}</vs-radio>
                    <vs-radio class="w-full my-2 ml-4" v-for="(item, index) in unitList" :key="index" v-model="unit" :vs-name="'unit' + index" :vs-value="item.enterprise_id" @change="getData">{{ item.name }}</vs-radio>
                </div>
                <div class="mb-base grid grid-cols-1 gap-4 whitespace-no-wrap">
                    <vs-radio v-model="readStatus" vs-name="readStatus" vs-value="0">未判讀</vs-radio>
                    <vs-radio v-model="readStatus" vs-name="readStatus" vs-value="1">已判讀</vs-radio>
                    <vs-radio v-model="readStatus" vs-name="readStatus" vs-value="2">全部</vs-radio>
                </div>
            </template>
            <template v-slot:content>
                <div class="p-8">
                    <item v-for="(item, index) in data" :key="index" :data="item"></item>
                </div>
            </template>
        </list>
    </div>
</template>
<script>
import { getTbFiles, getMemberFilter } from '@/api/user'
import list from '@/components/Module/list.vue'
import item from '@/components/Tb/TbItem.vue'
import { mapState } from 'vuex'
import { debounce } from 'lodash'
export default {
    components: { list, item },
    data() {
        return {
            menu: false,
            data: [],
            total: 0,
            limit: 50,
            page: 1,
            isLoading: false,
            readStatus: 0,
            query: '',
            unitList: [],
            unit: null,
        }
    },
    mounted() {
        this.getMemberFilter()
        // this.getData()
        this.createBus()
    },
    watch: {
        query() {
            this.getData()
        },
        readStatus() {
            this.getData()
        },
    },
    computed: {
        ...mapState(['windowWidth']),
        partner_id() {
            return this.$store.getters.partner
        },
    },
    methods: {
        getData: debounce(function () {
            this.isLoading = true
            const payload = {
                query: this.query,
                limit: this.limit,
                page: this.page,
            }
            if (this.readStatus == 0) payload['hasDiagnosis'] = 0
            if (this.readStatus == 1) payload['hasDiagnosis'] = 1
            if (this.unit) payload['eid'] = this.unit
            if (!this.unit) payload['eids'] = _.map(this.unitList, 'enterprise_id')
            getTbFiles(payload)
                .then((res) => {
                    this.data = res.data.data.data
                    this.total = res.data.data.total
                })
                .finally(() => {
                    this.isLoading = false
                })
        }, 200),
        // 開關篩選sidebar (手機版)
        switchFilter() {
            this.menu = !this.menu
        },
        createBus() {
            this.$bus.$on('changePage', (page) => {
                this.page = page
                this.getData()
            })
        },
        // 取公司和群組清單
        getMemberFilter() {
            const payload = {
                partner_id: this.partner_id,
            }
            getMemberFilter(payload)
                .then((res) => {
                    if (res.data.status === 'OK') {
                        this.unitList = res.data.data.enterprise_list
                        console.log('this.unitList', this.unitList)
                    }
                })
                .finally(() => {
                    this.getData()
                })
        },
    },
    beforeDestroy() {
        this.$bus.$off('changePage')
    },
}
</script>
